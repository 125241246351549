import React from 'react'

import {Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Footer from '../components/footer'

import '../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import {responsive} from '../utils/responsive'

const FaqPage = () => {
    return (<Layout>
        <SEO title="FAQ"/>
        <Banner class_suffix="deep"/>
        <div className="about-container">
            <div className="about-content">
                <p>
                    Comment se prononce PolyHx?
                    «Poly hacks»
                    À quelle fréquence donnez-vous des ateliers?

                    Les ateliers sont généralement donnés aux deux semaines cette session.
                    Les ateliers sont-ils enregistrés?

                    En général, oui, mais nous vous invitons à participer durant les ateliers pour poser des questions.
                    Les ateliers sont-ils aussi en présentiels?

                    Pour l'instant, non, mais les traditionnels ateliers avec de la pizza gratuite seront de retour cette année.
                    Que fait PolyHx?

                    En plus de la cybersécurité, PolyHx organise aussi des ateliers, conférences et compétitions sur l'intelligence artificielle, le développement logiciel et des hackathons.
                    Quel est le programme de PolyHx cette année?

                    Ateliers techniques avec des professionnels, conférences, événements de réseautage, création de CTF... Restez informés!
                    Comment fait-on pour rejoindre l'équipe PolyHx?

                    Faire partie de l'équipe PolyHx donne accès à des billets pour des compétitions et des plateformes d'entraînement. Pour la rejoindre, il faut être étudiant à Polytechnique et faire une demande à ceux qui ont le rôle exécutif.

                </p>
            </div>
        </div>
    </Layout>)
}
export default FaqPage
